.panelDiv {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    border-radius: 2px;
}

.panelDiv:hover {
    background-color: rgb(226, 226, 226);
}

.panelDiv>a {
    padding-left: 0.5rem;
}

.panelDiv>a:hover {
    cursor: pointer;
}

.listItemDiv {
    border-radius: 2px;
}

.listItemDiv:hover {
    background-color: rgb(226, 226, 226);
}

.listItemDiv>a {
    padding-left: 0.5rem;
}

.listItemDiv>a:hover {
    cursor: pointer;
}

.panelLink {
    color: #337ab7;
    text-decoration: none;
}

.panelLink:hover {
    cursor: pointer;
    color: #23527c;
    text-decoration: underline;
}