.demographicInfoItem {
    padding-left: 45px;
}

.memberInfo ul, .eligibilityInfo ul {
    font-size: 16px;
    list-style: none;
    padding-top: 0.3rem;
    padding-left: 0;
}

.memberInfo li, .eligibilityInfo li {
    padding-top: 1rem;
    padding-left: 1rem;
}

.memberInfo li:nth-child(1), .eligibilityInfo li:nth-child(1) {
    padding-top: 0;
}

.memberInfo li b, .eligibilityInfo li b {
    color: #666;
}

.hccProfilePanelWindow .panel-body, .hccProfilePanel .panel-body, .starProfilePanel .panel-body,
.capturePanel .panel-body {
    padding: 0;
}

.hccProfilePanel .panel-body, .starProfilePanel .panel-body {
    height: 350px;
    overflow-y: auto;
}

.capturePanel.cdi-alert-panel.panel.panel-default .panel-body {
    height: 298px;
    overflow-y: auto;
}

.capturePanel.cdi-alert-panel.panel.panel-default.panel {
    border-bottom: 1px solid #ddd;
    box-shadow: none;
}

.capturePanel.claims-panel.panel-default.panel {
    border-bottom: 1px solid #ddd;
    box-shadow: none;
    height: 353px;
    overflow-y: auto;
    overflow-x: auto;
}

.hccProfilePanel .profileButton, .hccProfilePanelWindow .profileButton, .starProfilePanel .profileButton {
    font-weight: bold;
    vertical-align: top;
    margin-right: 2rem;
}

.claims-cdi-tab > .panel > .panel-body {
    padding: 0;
    border: none;
}

/* todo: revisit this; delete if unused
.profileTable, .cdiAlertTable {
    font-size: 12px;
}*/

/* todo: revisit this; delete if unused
/*.cdiAlertTable .react-bs-container-body {
    max-height: 200px !important;
}*/

/* todo: revisit this; delete if unused
.claimsProfileTable {
    overflow: scroll;
}
*/

/* todo: revisit this; delete if unused
.claimsProfileTable > .react-bs-table > .react-bs-container-header, 
.claimsProfileTable > .react-bs-table > .react-bs-container-body {
    overflow: visible !important;
}*/

.capturePanel > div {
    padding-top: 2.4rem;
}

.capture-panel-no-top-padding > div {
    padding-top: 0
}

#claims-cdi-tabs > .tab-content > div > .capturePanel > .claimsProfileTable {
    padding-top: 2rem;
}

.capturePanel.panel.panel-default,
.capturePanel .panel-heading,
.capturePanel .panel-body {
    border-top: none;
    border-bottom: none;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.capturePanel.panel.panel-default > ul {
    box-shadow: none;
}


.capturePanel.panel.panel-default > ul > li {
    border: none;
}

.hccProfileTable > .react-bs-table, .starProfileTable > .react-bs-table,
.capturePanel > div > .react-bs-table-container > .react-bs-table,
.claimsProfileTable > .react-bs-table,
.cdiAlertTable > .react-bs-table {
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-top-right-radius: 0px;
    border-top-left-radius: 0px;
}

.imageViewerTable > .react-bs-table {
    border-right: none;
    border-bottom: none;
}

.imageViewerNLPHCCTable > .react-bs-table {
    border-bottom: none;
}

.cdiAlertTable > .react-bs-table > .react-bs-container-header > table > thead > tr > th:last-child {
    padding-top: 0.74rem;
}

.cdiAlertTable > .react-bs-table > .react-bs-container-header > table > thead > tr > th:nth-child(2) {
    border-left: none;
}

.capturePanel > div > .react-bs-table-container > .react-bs-table > .react-bs-container-header > table > thead > tr > th:nth-child(2) {
    border-left: none;
}

.hccProfileInset > .react-bs-table {
    padding: 0;
    margin: 0;
    border: none;
}

.hccProfileInsetWell.well {
    padding: 0px;
}

/* Background colors for HCC profile icons */
.rapsBackground {
    background: url('../images/RAPS.gif') no-repeat #c3cfd6;
    background-size: 100% 100%;
}

.rapsPaidBackground {
    background: url('../images/RAPSPaid.gif') no-repeat #b1e1ab;
    background-size: 100% 100%;
}

.claimsBackground {
    background: url('../images/Claims.gif') no-repeat #e3e534;
    background-size: 100% 100%;
}

.edpsBackground {
    background: url('../images/EDPS.gif') no-repeat #aedbed;
    background-size: 100% 100%;
}

.rapsEdpsBackground {
    background: url('../images/RapsEdps.gif') no-repeat #aedbed;
    background-size: 100% 100%;
}

.edgeBackground {
    background: url('../images/Edge.gif') no-repeat #ADD8E6;
    background-size: 100% 100%;
}

.targeted {
    background-color: #f7f6bb !important;
    cursor: pointer;
}

.non-targeted {
    cursor: pointer;
}

.opp-capture-tooltip {
    display: inline-block;
    border-bottom: 1px dotted black;
}

.opp-capture-tooltip > .opp-capture-tooltip-text {
    visibility: hidden;
    background-color: white;
    color: black;
    font-size: 11px;
    text-align: center;
    border-radius: 6px;
    border: 1px solid #aaa;
    padding: 5px;
    white-space: normal;
    position: absolute;
}

.opp-capture-tooltip:hover .opp-capture-tooltip-text {
    visibility: visible;
}

.diagDetailModal header h4, .starDetailModal header h4 {
    padding: 5px 0 5px 10px;
    margin: 0;
    font-weight: bold;
}

.diagDetailModal [class*="col-"], .starDetailModal [class*="col-"] {
    padding: 0;
    margin-right: 5px;
    margin-left: 5px;
}

.diagDetailModal [class*="col-"] {
    width: 32%;
    max-width: 32%;
}

.starDetailModal [class*="col-"] {
    width: 48%;
    max-width: 48%;
}

.diagModalContainer {
    padding: 10px;
    margin-bottom: 10px;
    font-size: 14px;
}

.diagModalContainer .row {
    margin-left: auto;
    margin-right: auto;
}

.diagModalContainer span {
    padding-bottom: 3px;
}

/*.diagModalAttribute*/
.diagModalContainer .row < span:nth-child(1) {
    float: left;
    font-weight: bold;
}

/*.diagModalValue*/
.diagModalContainer .row < span:nth-child(2) {
    float: right;
}

.diagModalContainer .commentBox {
    white-space: pre-line;
    font-size: 12px;
    border: solid 1px #ccc;
    padding: 5px;
}

/* button styles */
.diagModalCenter {
    display: flex;
    justify-content: center;
    padding-right: 1rem;
}

.diag-qa-button-wrapper > .button {
    margin-left: 5px;
}

.diag-qa-button-wrapper > .button > .glyphicon {
    font-weight: bold;
}

.deleteButton > .glyphicon {
    color: red;
    text-shadow: 1px 1px #ececec;
}

.approveButton > .glyphicon {
    color: #3adb76;
    text-shadow: 1px 1px #ececec;
}

.navbar-left.nav-no-padding > li > a {
    padding-left: 0px;
}

.diag-accepted-status {
    color: seagreen;
    font-weight: 600;
}

.diag-rejected-status {
    color: red;
    font-weight: 600;
}

.fileupload input[type=file] {
    display: none;
    margin: 10px;
  }
  
.fileupload input[type=file] + label {
    display: inline-block;
    margin: 20px;
    padding: 4px 32px;
    background-color: #FFFFFF;
    border: solid 1px #666F77;
    border-radius: 6px;
    color: #666F77;
    cursor: pointer;
}

.fileupload input[type=file]:active + label {
    background-image: none;
    background-color: #2D6C7A;
    color: #FFFFFF;
}

/* allows for copy-paste into an empty react-select component when input is selected */
.form-group.provider-typeahead-form-group > div > div > .Select.is-focused > .Select-control > .Select-multi-value-wrapper > .Select-placeholder {
    z-index: -1;
}

.form-group.provider-typeahead-form-group > div > div > .Select > .Select-control > .Select-multi-value-wrapper > .Select-input {
    width: 100%;
}

.form-group.provider-typeahead-form-group > div > div > .Select > .Select-control > .Select-multi-value-wrapper > .Select-input > input {
    width: 100% !important;
}

/* PIR */
.rafSelectButton {
    border-radius: 20px !important;
}

.rafSelectButton.active {
    background-color: #5cb85c !important;
    color: white !important;
    text-shadow: 0 1px 0 #555;
}

.rafSelectButton:focus, .rafSelectButton.active:focus {
    outline: 0 !important;
}

/* Alert Workflow Update */
.alert-workflow-header, .alert-workflow-header.panel {
    /*Need to uncomment below line to show Header if running under React Debug model */
    /*margin-top: 5.2rem;*/
    margin-bottom: 2rem;
    background-color: #bac7f826;
}

.alert-workflow-header > h4 {
    padding-top: 0.33rem;
    padding-left: 2rem;
    padding-bottom: 0rem;
    padding-right: 2rem;
}

.alert-workflow-header > h4 > .form-group {
    margin-bottom: 0;
}

.alert-workflow-header > h4 > .form-group > label {
    font-style: oblique;
    margin-bottom: 0;
}

.alert-workflow-subcontainer {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
    margin-bottom: 1rem;
}

.alert-workflow-subcontainer > div > hr,
.alert-workflow-exceptions-container > div > hr {
    margin-top: 0;
}

.alert-workflow-subcontainer > div > .row > .col > .form-group,
.alert-workflow-exceptions-container > div > .row > .col > .form-group {
    margin-bottom: 5px;
}

/*.alert-workflow-subcontainer > div.collapse {
    border-top: none;
    border-right: 1px dashed rgb(208, 208, 208);
    border-bottom: 1px dashed rgb(208, 208, 208);
    border-left: 1px dashed rgb(208, 208, 208);
}*/

.alert-workflow-exceptions-container {
/*    max-height: 360px;
    overflow-x: hidden;
    overflow-y: scroll;*/
    padding-left: 0.5rem;
    padding-right: 0.5rem;
/*    padding-bottom: 200px;   */
    margin-top: 1rem;
    margin-bottom: 2rem;
}

.alert-workflow-exceptions-edit-container > div > .row:first-of-type {
    margin-top: 2.4rem;
}

.alert-workflow-exceptions-edit-container > div:first-of-type > .row:first-of-type  {
    margin-top: 0;
}

.alert-workflow-exceptions-edit-container > div > .row > div > .form-group {
    margin-bottom: 0;
    padding-left: 0
}

.alert-workflow-alert-response-container {
/*    max-height: 360px;
    overflow-x: hidden;
    overflow-y: scroll;*/
    padding-left: 0.5rem;
    padding-right: 0.5rem;
/*    padding-bottom: 200px;*/
}

.alert-workflow-alert-response-container > .alert-workflow-alert-response-group:first-of-type > .row:first-of-type {
    margin-top: 0;
}

.alert-workflow-alert-response-container > .alert-workflow-alert-response-group:first-of-type > .row:first-of-type
> div > h5 {
    margin-top: 0;
    margin-bottom: 0
}

.alert-workflow-alert-response-container > .alert-workflow-alert-response-group > .row > div > h5 {
    margin-bottom: 0
}

.alert-workflow-alert-response-group > .row:first-of-type {
    margin-top: 2rem;
}

.alert-workflow-alert-response-group > .row {
    margin-top: 1rem;
}