#primary_nav_wrap {
  margin-top: 15px;
}

#primary_nav_wrap ul {
  list-style: none;
  position: relative;
  float: left;
  margin: 0;
  padding: 0;
}

#primary_nav_wrap ul a {
  display: block;
  background-color: #333;
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  line-height: 32px;
  padding: 0 15px;
}

#primary_nav_wrap ul li {
  display: list-item;
  position: relative;
  float: left;
  font-weight: 700;
  margin: 0;
  padding: 0;
}

#primary_nav_wrap ul li.current-menu-item {
  background: #ddd;
}

/*#primary_nav_wrap ul li:hover {
  background: #f6f6f6;
}*/

#primary_nav_wrap ul li:hover > ul {
  display: block;
}

#primary_nav_wrap ul ul {
  display: none;
  position: absolute;
  top: 100%;
  left: 0;
  background: #fff;
  padding: 0;
}

#primary_nav_wrap ul ul a {
  line-height: 120%;
  padding: 10px 15px;
}

#primary_nav_wrap ul ul a:hover {
  background-color: #444;
}

#primary_nav_wrap ul ul li {
  float: none;
  width: 200px;
}

#primary_nav_wrap ul ul ul {
  top: 0;
  left: 100%;
}
