.loader {
    position: fixed;
    z-index: 9999;
    height: 2em;
    width: 2em;
    overflow: show;
    margin: auto;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.loader:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
}

.hidden {
    display: none;
}

.vertical-center {
    vertical-align: middle !important;
}

.coding-actionbar-desc-div {
    height: 34px;
    padding: 8px 0;
}

.topLogo:first-child {
    font-size: 1.2em;
    padding-left: 1.6rem;
}

.topLogo:nth-of-type(2) {
    padding-left: 1.4rem;
}

.topLogo {
    color: #fff;
    font-size: 1em;
    padding-left: 1rem;
    text-shadow: none;
}

.topLogo .tooltipText {
    visibility: hidden;
    background-color: #333;
    color: #fff;
    text-align: center;
    text-shadow: none;
    border-radius: 6px;
    padding: 5px 5px;
  
    /* Position the tooltip */
    position: relative;
    z-index: 1;
    top: 30px;
    left: -90px;
}
  
.topLogo:hover .tooltipText {
    visibility: visible;
}

/* pdf js */
#viewerContainer {
        position: absolute;
        width: 100%;
        height:600px;
        overflow-y:scroll;
        top: -10px;
        bottom: 4rem;
        left: 0;
        right: 0;
        background-color: rgba(82, 86, 89, 255);
}
    
.textLayer .highlight.selected {
    background-color: orangered;
}
