.sectionHeader {
    margin-bottom: 0.5rem;
    font-size: large;
    color: rgb(0, 0, 150);
}

.threeSectionCol {
    float: left;
    width: 33%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 4rem;
}

.threeSectionHeader {
    padding-left: 2rem;
    font-weight: bold;
}

.threeSectionResults {
    font-weight: bold;
}

.twoSectionCol {
    float: left;
    width: 50%;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 4rem;
}

.twoSectionHeader {
    padding-left: 2rem;
    font-weight: bold;
}

.twoSectionResults {
    font-weight: bold;
}

.noteTitle {
    color: rgb(0, 0, 150);
}

.rawTableColumnHeader {
    background-color: #f5f5f5;
}

#datedTableWrapper > .datedTable {
    width: 90%;
    margin-left: 4rem;
    margin-bottom: 1rem;
}

.datedTableHeader {
    font-weight: bold;
    margin-left: 2rem;
    margin-bottom: 0.5rem;
}

.glyphicon {
    color:#337ab7;
}

.width10{
    width: 10%;
}

.width15{
    width: 15%;
}

.width20{
    width: 20%;
}

.width30{
    width: 30%;
}

.width50{
    width: 50%;
}

.width70{
    width: 70%;
}

.oneSectionCol{
    padding-left: 4rem;
    margin-left: -15px;
}