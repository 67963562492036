.UserConfirmedRanking form label {
    font-size: 12px;
}

.rankingTable tbody {
  background: white !important;
}

.rankingTable tbody th, .rankingTable tbody td, .rankingTable thead th, 
.enrolleeTable tbody th, .enrolleeTable tbody td, .enrolleeTable thead th, .enrolleePanel h5 {
  font-size: 12px;
  vertical-align: middle !important;
}

.rankingTable tbody td label {
  font-size: 12px;
}

#formControlsCoversheetID {
  background-image: none;
}