.reportContainer {
    background: white;
    margin-bottom: 2%;
    margin-top: 2%;
}

.innerReportContainer {
    background: white;
    border: 1px solid #ccc;
    min-height: 200px;
    margin-left: 10%;
    margin-right: 10%;
    padding-left: 2%;
    padding-right: 2%;
    box-shadow: inset 0 1px 0 hsla(0,0%,100%,.15), 1px 1px 1px rgba(0,0,0,.075);
}

.hccReportTable thead th, .hccReportTable tbody td {
    width: 33%;
}

.hccCell1 {
    background-color: rgba(235, 235, 235, 0.6);
}

.hccCell2 {
    background-color: rgba(0, 161, 224, 0.45);
}

.hccCell4 {
    background-color: rgba(5, 181, 90, 0.6);
}

.hccCell5 {
    background-color: rgba(181, 17, 5, 0.6);
}

.hccCell6 {
    background-color: rgba(211, 132, 4, 0.6);
}

.legendLabel {
    display: inline-block;
    min-width: 150px;
    padding: 5px;
    color: black;
}

.riskMitigationTable tr th, .claimsAuditTable tr th {
    background-color: #f5f5f5;
    text-align: left;
}

.riskMitigationTable tr td:first-child, .claimsAuditTable tr td:first-child {
    padding-left: 30px;
}

.riskMitigationTable .fa {
    margin-left: 10px;
}

.projectStatusReportModal .modal-content {
    width: 1200px;
}

.projectStatusReportModal .modal-dialog {
    width: fit-content !important;
    font-size: 0.9em;
}

.coderQACoderMultiselect {
    max-height: 200px !important;
    overflow: scroll !important;
}

.coderQAScoringReportTable {
    max-width: 1000px;
}

.coderQAScoringReportTable tbody tr:hover {
    background-color: #fff;
}

.coderQAScoringReportInnerTableRow:hover .coderQAScoringReportInnerTableRow {
    background-color: #e5ffff;
    border-color: #c1c1c1;
}

.tableHeaderRow th,
.tableHeaderRow th table tr th {
    background-color: #ececec;
}

.tableHeaderRow:hover th,
.tableHeaderRow:hover th table tr th {
    background-color: #ececec;
}

.coderQAScoringReportNameColumn {
    background-color: #ececec !important;
}

.scorecardDivTableHeader th,
.scorecardDivTableHeader:hover th  {
    background-color: #f5f5f5;
}

.fixedHeader {
    position: absolute;
    border-bottom: none !important;
    border-left: 1px solid #ececec !important;
    border-top: 1px solid #c1c1c1 !important;
    margin-left: -1px !important;
}

.blankHeader {
    position: absolute;
    border-bottom: none !important;
    border-left: 1px solid #ececec !important;
    border-top: none !important;
    margin-left: -1px !important;
    min-height: 40px;
}

.coderQARowData {
    padding: 0 !important;
}

.coderQARowData:first-of-type {
    margin-left: 300px !important;
}

.coderQARowData table {
    margin: 0 !important;
}

.starsProductivityReport thead tr th {
    text-align:center;
}

.starsProductivityReport .row {
    padding-top: 5px;
    padding-bottom: 5px;
}

.starsProductivityReport div.dropdown button {
    width: 60px;
}

.starsProductivityReport ul.dropdown-menu {
    text-align: center;
}

.starsProductivityReport ul.dropdown-menu,
.starsProductivityReport ul.dropdown-menu li[role="presentation"] {
    min-width: 80px;
    width: 80px;
}

.starsProductivityReport th.desc::after {
    content: "▾";
    position: fixed;
    margin-left: 8px;
}

.starsProductivityReport th.asc::after {
    content: "▴";
    position: fixed;
    margin-left: 8px;
}

.starsProductivityReport .paddedBtn {
    margin-top: 20px;
    margin-bottom: 20px;
}

.starsProductivityReport #toDate_group input[readonly], 
.starsProductivityReport #fromDate_group input[readonly] {
    background-color: unset;
}

.hostPlan .title{
    text-align: center;
    margin-bottom: 50px;
}
.hostPlan .date-section{
    margin-top: 60px;
}
.hostPlan .search-button{
    margin-top: 25px;
    float: right;
    margin-right: 100px;
}
.hostPlan .table-section{
    margin-top: 15px;
    margin-bottom: 80px;
}
.hostPlan .export-table-data{
    float: right;
    margin-top: -50px;
}