.print-container {
    margin-top: 2rem;
    padding: 0% 2.4% 1% 2.4%;
}

@media print {
    .nav-header {
        display: none;
    }

    .print-container {
        margin-top: 0rem;
        padding: 0% 0% 0% 0%;
        font-size: 10px;
        border: 0px;
    }

    hr {
        display: none;
    }

    div.react-bs-container-header > table.tableHeader th {
        border-color: #666 !important;
        border-bottom: none !important;
    }

    div.react-bs-container-body > table.table-bordered td {
        border: 1px solid #666 !important;
    }

    div.react-bs-container-body > table.table-bordered tr {
        page-break-after: always;
    }
}

@page {
    margin: 0.4in;
}