:root {
    --off-white: #fafbfc;
    --dark-gray: #5d5d5d;
    --gray: #d5d5d5;
    --blue: #3b4177;
    --dark-blue: #262b68;
    --white-shadow: #fbfbfb;
}

#radvRoot {
    background-color: rgb(255, 255, 255);
}

#container {
    margin-top: 1rem;
    min-height: 950px; /* todo: can this be safely removed? */
}

.container-body {
    padding: 0% 1% 1% 1%;
}

.nav.navbar-nav.navbar-left > li > a {
    padding: 1rem 0rem;
}

.nav.navbar-nav.navbar-right > li > a, .nav.navbar-nav.navbar-right > li > span {
    display: block;
    padding: 1rem 1rem 1rem 0;
}

.modal-content {
    max-height: 900px;
    overflow: auto;
}

.modal-dialog-fullwidth {
    width: fit-content !important;
    font-size: 0.9em;
    padding: 0 1rem;
}

.modal-dialog-small-vertical-alignment {
    position: absolute !important;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.modal-dialog-large-vertical-alignment {
    position: absolute !important;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%) !important;
}

.modal-wide > .modal-dialog {
    width: 60%;
}

.modal-medium-wide > .modal-dialog {
    width: 70%;
}

.modal-extra-wide > .modal-dialog {
    width: 85%;
}

.custom-modal-tall {
    max-height: calc(100vh - 100px);
    overflow-y: auto;
}

.buttonLink {
    background:none !important;
    border:none !important;
    padding:0 !important;
    font: inherit;
    color: #0645AD;
    cursor: pointer;
    box-shadow: none !important;
}

/* todo: remove unused background rule once finalized (leaving for now) */
.react-bs-table-container > .react-bs-table > .react-bs-container-header > table > thead > tr > th {
    /*background: #e9ecef;*/
    background-color: #f5f5f5;
    border-bottom-width: 0px;
    vertical-align: top;
    vertical-align: -webkit-baseline-middle;
    white-space: normal;
}

.react-bs-table-container:not(.clickable-table):not(.expanded-table) > .react-bs-table > .react-bs-container-body > table > tbody > tr > td {
    background-color: #fffffe;
    white-space: normal;
}

/*override disabling table background color change on hover and default pointer option (for hedis chase grid and any tables with clickable rows*/
.clickable-table > .react-bs-table > .react-bs-container-body > table > tbody > tr > td {
    cursor: pointer;
    white-space: normal;
}

.clickable-table > .react-bs-table > .react-bs-container-body > table > tbody > tr:hover > td {
    background-color: #F9F9F9;
}

.additionalOptions {
    border: none !important;
}

.landingSearchResults table thead th {
    font-size: 12px;
    vertical-align: middle !important;
    white-space: pre-wrap;
    word-break: break-word;
    text-align: left;
    padding-bottom: 5px;
}

.landingSearchResults table thead th .order {
    display: none;
}

.pdfModal .modal-dialog{
    width: 900px !important;
}

.pdfModal .modal-body {
    height: 700px !important;
}

.workflow-title {
    font-size: 28px;
}

.memberSelectionTable > .react-bs-table-pagination > div > div > ul {
    margin: 0;
}

.spinning {
    -webkit-animation-name: spin;
    -webkit-animation-duration: 3000ms;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: linear;
    -moz-animation-name: spin;
    -moz-animation-duration: 3000ms;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: linear;
    -ms-animation-name: spin;
    -ms-animation-duration: 3000ms;
    -ms-animation-iteration-count: infinite;
    -ms-animation-timing-function: linear;

    animation-name: spin;
    animation-duration: 3000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@-ms-keyframes spin {
    from { -ms-transform: rotate(0deg); }
    to { -ms-transform: rotate(359deg); }
}
@-moz-keyframes spin {
    from { -moz-transform: rotate(0deg); }
    to { -moz-transform: rotate(359deg); }
}
@-webkit-keyframes spin {
    from { -webkit-transform: rotate(0deg); }
    to { -webkit-transform: rotate(359deg); }
}
@keyframes spin {
    from {
        transform:rotate(0deg);
    }
    to {
        transform:rotate(359deg);
    }
}

/* transforms an element into its mirror image */
.mirror-icon {
    -webkit-transform: scale(-1, 1);
    -moz-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    -o-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

/* react-loader content class */
.loaded-content-full-height {
    height: 100%;
}

.nav.nav-tabs > li > a {
    color: #6c757d;
}

.panel-heading {
    color: #55595c;
    border: none;
}

/* todo: revisit this; delete if unused
.panel-default > .panel-heading {
    background-image: none !important;
    background-color: #e9ecef;
}*/

.requiredField:before {
    content: "* ";
    color: red;
    font-size: 1em;
}

.uppercaseText {
    text-transform: uppercase;
}

.errorMessage {
    font-weight: bold;
    color: red;
}

.toast-top-right-below-nav {
    top: 56px;
    right: 12px
}

.dropbox {
    background-color:  rgba(221, 222, 221, 0.62);
    cursor: pointer;
    font-size: 1.25rem;
    position: relative;
    padding: 1rem 1rem;
    outline: 2px dashed #acb0b7;
    outline-offset: -10px;
    -webkit-transition: outline-offset .15s ease-in-out, background-color .15s linear;
    transition: outline-offset .15s ease-in-out, background-color .15s linear;
}

.dropbox > .message {
    padding-top: 3.4rem;
    text-align: center;
}

.dropbox > .message > h5 {
    color: #adadad;
    cursor: pointer !important;
    font-size: 16px;
    margin-top: 2.4rem;
}

.dropbox > .message > span.glyphicon {
    color: #adadad;
    font-size: 36px;
}

.dropbox-dragover {
    outline-color: #ffffff;
    background-color: #5776ab;
}

.dropbox-dragover > .message > h5, .dropbox-dragover > .message > span.glyphicon {
    color: #ffffff
}

.practiceModal > .modal-dialog {
    min-width: 860px;
}

.custom-dialog {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
}

.hedisAssignmentModal .modal-body{
    height: 300px;
}

button > a, button > a:hover {
    color: #333;
    text-decoration: none;
}

.projectEditModal .modal-dialog {
    width: 500px;
}

.select-projectDropDown div {
    max-height: 90px;
}

.abstraction-comment-panel > .panel-heading {
    padding: 8px 1rem;
}

.abstraction-comment-panel > .panel-heading > .abstraction-comment-panel-label {
    display: inline-block;
}

.abstraction-comment-panel > .panel-heading > .abstraction-comment-panel-button-wrapper {
    display: inline-block;
    float: right;
}

.abstraction-comment-panel > .panel-heading > .abstraction-comment-panel-edited-wrapper {
    display: inline-block;
    float: right;
    line-height: 1.5;
    padding: 0.5rem 1rem;
}

.nav.hedis-sidebar-nav > li > a:focus,
.nav.hedis-sidebar-nav > li > a:hover {
    background-color: initial;
}

.collapsible {
    border: 2px;
    border-color: black;
    box-shadow: 2px 2px 6px darkgray;
}

.collapsible-header {
    cursor: pointer;
    padding: 0.33rem 1rem;
}

.collapsible-spacer {
    margin-top: 0;
    margin-bottom: 1rem;
}

.subsection-collapsible-header-row:not(:nth-child(2)) {
    cursor: pointer;
    margin-top: 2rem;
}

.measure-capture-header-spacer {
    /*todo*/
}

.measure-capture-field-group-row:not(:first-child) {
    margin-top: 2rem;
}

.dropdown-filter .dropdown-menu {
    top: 80% ;
}

/* todo: remove the !important usages below */
.dropdown-filter .dropdown-filter-button {
    padding: 0px !important;
    font-size: 14px !important;
    font-weight: bold;
    background-image: none;
    border-color: transparent !important;
    box-shadow: none !important;
    margin-left: 0px !important;
}
  
.dropdown-filter .btn-group .open,
.dropdown-filter .dropdown-toggle {
    background-color: transparent !important;
}

.expanded-table .table > tbody > tr > td {
    border-top: none;
}

.expanded-table-header > thead > tr > th {
    background-color: white !important;
}

.expanded-table .table > tbody > tr:hover {
    cursor: default;
    background-color: #F9F9F9;
}

/* eliminates overflow ellipsis behavior on an indicator 
column in an expandable react bootstrap table on resize */
.react-bs-container-body .react-bs-table-expand-cell {
    overflow: hidden;
}

/* global nav -- begin */
.nav-header {
    background: var(--off-white);
    border-bottom: 1px solid var(--gray);
}

.nav-header > .nav {
    padding-top: 0.33rem;
}

.nav-dropdown > a {
    color: var(--dark-gray);
}

.nav-dropdown > a > span {
    color: var(--dark-blue);
}

ul.nav > li.dropdown.nav-dropdown > a,
ul.nav > li.dropdown.nav-dropdown > a:focus {
    background-color: inherit;
    color: var(--dark-gray);
}

li.dropdown.nav-dropdown > ul.dropdown-menu > li > a:hover {
    background-color: var(--white-shadow);
    background-image: none;
}

li.dropdown.nav-dropdown > ul.dropdown-menu > li.nav-dropdown-active,
li.dropdown.nav-dropdown > ul.dropdown-menu > li.nav-dropdown-active > a {
    color: var(--blue);
    font-weight: bold;
}

.nav-header > ul.nav.navbar-nav > li > a {
    color: var(--dark-gray);
}

.nav-header > ul.nav.navbar-nav > li:not(:first-child) > a:focus,
.nav-header > ul.nav.navbar-nav > li:not(:first-child) > a:hover {
    background-color: inherit;
    color: var(--blue);
    font-weight: bold;
}

.nav-navitem-active {
    color: var(--blue);
    font-weight: bold;
}

.nav-line-of-business-container {
    display: inline-block;
    float: right;
    margin-top: 1rem;
    margin-right: 2.4rem;
    width: 134px;
}

.nav-userinfo {
    float: right;
    margin-top: 2rem;
    margin-right: 1.8rem;
    margin-left: 0.2rem;
}

.nav-userinfo > p {
    color: #5D5D5D;
}

/* global nav -- end */